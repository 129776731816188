<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ errors }"
            :vid="name"
            :rules="validationRules"
        >
            <div class="input form-floating">
                <input
                    type="text"
                    inputmode="decimal"
                    class="form-control"
                    :id="name"
                    v-mask="mask"
                    auto-complete="off"
                    :class="{ 'is-invalid': errors[0], 'rounded-bottom-0': appendBottomText }"
                    :name="name"
                    :placeholder="placeholder"
                    v-model="currencyValue"
                    @blur="onBlur($event.target)"
                    @focus="onFocus($event.target)"
                >
                <label
                    v-if="label"
                    :for="name"
                >{{ label }}</label>
                <div
                    v-if="appendBottomText"
                    class="append-bottom-text bg-light small p-1 ps-2 rounded-bottom border-bottom border-left border-right"
                >
                    {{ appendBottomText }}
                </div>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    /**
     * Use for all text input fields. Has label, validation, placeholder and filters.

     * @prop{String} name (required) To modify the text formatting. Can be 'uppercase|lowercase'.

     * @prop{String} validation (optional) A string with the vee-validate rules string. ie 'required|max:7'
     * @prop{String} label (optional) A label above the input.
     * @prop{String} classes (optional) A string of any classes you want to apply to the input field
     * @prop{String} placeholder (optional) The text when the input is blank.
     * @prop{String} filter (optional) To modify the text formatting. Can be 'uppercase|lowercase'.
     * @prop{Boolean} focus (optional) To set focus on the input. Defaults to false.
     */
    import { ValidationProvider } from 'vee-validate'
    import createNumberMask from 'text-mask-addons/dist/createNumberMask'
    import { logger } from '@/utils/logger'
    import { formInputMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormCurrencyField',
        mixins: [formInputMixin],
        props: {
            placeholder: {
                type: String,
                default: '$0.00',
            },
            decimalsToShow: {
                type: Number,
                default: 0,
            },
            validationRules: { type: String, default: 'required' },
            appendBottomText: { type: String, default: '' },
        },
        components: {
            'validation-provider': ValidationProvider,
        },
        data() {
            return {
                currencyMask: createNumberMask({}),
                currencyValue: '',
                mask: '',
            }
        },
        watch: {
            currencyValue: function (newValue, oldValue) {
                // So here's what's up:
                // There's a bug where if you provide a function to v-mask, it causes an infinite render loop with vee-validate
                // #ILoveJavascriptFrameworks
                // The fix is to make mask a regular variable, and then update the value ourselves here
                // You can't use a computed property, because that's also a function
                // https://github.com/probil/v-mask/issues/511
                this.mask = this.currencyMask(newValue)

                logger.log(`currencyValue: ${oldValue} -> ${newValue}`)
                const filteredValue = parseFloat(newValue?.replace(/[$,]/gi, '')).toFixed(this.decimalsToShow)
                this.$emit('input', filteredValue)
            },
        },
    }
</script>
