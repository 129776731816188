import { getPolicyForExperiment } from '@/experiments/getPolicyForExperiment'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { ExperimentName } from '@/experiments/experimentName'

export const experimentsMixin = {
    computed: {
        heraclesParameter: function () {
            return getPolicyForExperiment(appSessionStorage.getItem(localStorageKey.experimentName) as ExperimentName)
        },
        ExperimentName: function () {
            return ExperimentName
        },
    },
}
