<template>
    <p
        class="safe-credit-score text-center fw-bold small"
        v-html="$t('pages.marketing.landing.safeCreditScore')"
    />
</template>

<script>
    export default {
        name: 'SafeCreditScore',
    }
</script>

<style>
    .thick-underline {
        border-bottom: 2px solid currentColor;
    }
</style>
