import { coApplicantHttpClient, httpClient, runWithRetryLogic } from '@/utils/http-client'

const updateEmployer = async (employer: string, jobTitle: string, isCoApplicant?: boolean) => {
    const postBody = {
        employer,
        jobTitle,
    }
    if (isCoApplicant) {
        return await coApplicantHttpClient.post('/submitEmployer', postBody)
    }
    return await httpClient.post('/submitEmployer', postBody)
}

const updateEmployerEmail = async (employerEmail: string, isCoApplicant?: boolean) => {
    const postBody = {
        employerEmail,
    }
    if (isCoApplicant) {
        return await coApplicantHttpClient.post('/submitEmployerEmail', postBody)
    }
    return await httpClient.post('/submitEmployerEmail', postBody)
}

const updateStatedIncome = async (statedIncome: string) => {
    const postBody = {
        statedIncome: parseInt(statedIncome),
    }
    return await runWithRetryLogic(async () => await httpClient.post('/submitStatedIncome', postBody), 1)
}

const initiateSoftPull = async () => {
    return await httpClient.get('/initiateSoftPull')
}

const getSoftPullResults = async (createdAfter: string) => {
    const config = { params: { createdAfter } }
    return await httpClient.get('/getSoftPullResults', config)
}

const pickPreQualTerms = async (preQualTerms: object) => {
    const payload = { preQualTerms }
    return await httpClient.post('/origination/pickPreQualTerms', payload)
}

const workNumberIncomeValidated = async () => {
    return (await httpClient.get('/workNumberIncomeVerification'))?.data?.success
}

const verifyEmployerEmailCode = async (authCode: string, isCoApplicant?: boolean) => {
    const postBody = {
        authCode,
    }
    if (isCoApplicant) {
        return await coApplicantHttpClient.post('/verifyEmployerEmailCode', postBody)
    }
    return await httpClient.post('/verifyEmployerEmailCode', postBody)
}

const updateStatedUsage = async (statedUsageAmount: string, statedUsage: string | null) => {
    const postBody = {
        statedUsageAmount,
        statedUsage: statedUsage || null,
    }
    return await httpClient.post('/submitStatedUsageAmount', postBody)
}

const doesLoanApplicationRequireThirdSignatory = async () => {
    return await httpClient.get('/doesLoanApplicationRequireThirdSignatory')
}

export {
    pickPreQualTerms,
    updateStatedIncome,
    updateEmployer,
    updateEmployerEmail,
    verifyEmployerEmailCode,
    updateStatedUsage,
    workNumberIncomeValidated,
    initiateSoftPull,
    getSoftPullResults,
    doesLoanApplicationRequireThirdSignatory,
}
