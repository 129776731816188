import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { startCase, toLower } from 'lodash'
import { runWithRetryLogic } from '@/utils/http-client'
import { postLegalDocuments } from '@/services/api'
import { logger } from '@/utils/logger'

export default {
    data() {
        return {
            errorText: '',
            submitting: false,
            loading: false,
            loadingTitle: 'Loading...',
            firstName: startCase(toLower(appSessionStorage.getItem(localStorageKey.firstName) || undefined)) || '',
            lastName: startCase(toLower(appSessionStorage.getItem(localStorageKey.lastName) || undefined)) || '',
        }
    },
    methods: {
        // Note that we generate legal documents on the frontend in order to keep a record of
        // what the applicant saw/had access to at various stages during origination. Initially
        // it seems better to generate these docs on the backend, however, we want to avoid a
        // situation where we start displaying other documents on the frontend but don't update
        // the set of docs we generate and save during origination. For that reason, it seems
        // better to generate these docs with a call from the frontend.
        generateAndSaveLegalDocuments: async function (legalDocumentTypes: string[]) {
            logger.info(`Generating and saving legal documents ${JSON.stringify(legalDocumentTypes)}`)
            return await runWithRetryLogic(async () => {
                const results = await postLegalDocuments(legalDocumentTypes)
                logger.info(`Results of generating and saving legal documents: ${JSON.stringify(results)}`)
                return results
            }, 1)
        },
        tryGenerateAndSaveLegalDocuments: async function (legalDocumentTypes: string[]) {
            try {
                logger.info(`Trying to generate and save legal documents ${JSON.stringify(legalDocumentTypes)}`)
                return await this.generateAndSaveLegalDocuments(legalDocumentTypes)
            } catch (e) {
                logger.fatal(`Unable to generate legal docs`, null /* event */, e)
            }
        },
    },
}
